<template>
	<div class="conten_right">
		<div v-show="fetching" class="contant">
			<h4>专家在线，问题解答</h4>
			<p class="con_desc">——— 提交后万方安全顾问专家会联系您 ———</p>
		    <div class="con_box">
		    	<div class="con_item">
		    		<span>手机号码:</span>
		    		<input readonly="readonly" @click="showphone('请拨打188 188 60088 ，188 188 63388 即刻连线专家')" placeholder="请输入手机号" type="text" name="">
		    	</div>
		    	<div class="con_item">
		    		<span>问题描述:</span>
		    		<textarea readonly="readonly" @click="showphone('请拨打188 188 60088 ，188 188 63388 即刻连线专家')" placeholder="请输入所在的城市及咨询的业务">
		    		</textarea>
		    	</div>
		    	<a class="con_from" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">提交</a>
		    </div>
		    		    
		</div>
		<p class="hots">热门产品</p>
		<ul class="recommandList">
          <li>
            <router-link to="/details" target="_blank" class="prodLink">
            <div class="recommandLeft">
              <img src="@/assets/images/b01.jpg" alt="">
            </div>
            <div class="recommandRight">
              <h5 id="prodName">网络安全等级保护测评服务</h5>
              <p>网络安全等级保护测评服务</p>
              <i>￥</i><span>40000</span> <b style="color:#888">/年起</b>
            </div>
            </router-link>
          </li>
          <li>  
            <router-link to="/icpedi" target="_blank" class="prodLink">
            <div class="recommandLeft">
              <img src="@/assets/images/icpedi/2.png" alt="">
            </div>
            <div class="recommandRight">
              <h5 id="prodName">ICP/EDI许可证第三方风险评估</h5>
              <p>ICP/EDI许可证第三方风险评估</p>
              <i>￥</i><span>0.00</span>
            </div>
            </router-link>
          </li>
          <li>
            <router-link to="/dengbaohegui" target="_blank" class="prodLink">
            <div class="recommandLeft">
              <img src="@/assets/images/dengbaohegui/3.png" alt="">
            </div>
            <div class="recommandRight">
              <h5 id="prodName">等保合规套餐</h5>
              <p>等保合规套餐</p> 
              <i>￥</i><span>0.00</span>
            </div>
            </router-link>
          </li>
        </ul>

        <div v-show="!fetching" style="background:#f4f4f4;height:20px;"></div>

        <p  v-show="!fetching"  class="hots">为你推荐</p>
        <ul v-show="!fetching" class="recommandList reco_article">
          <li>
            <router-link to="/consultDetail" target="_blank" class="prodLink">
              <p title="工信部：加强网络安全风险隐患排查，做好行业关键信息基础设施安全防护" class="art_conent">1、工信部：加强网络安全风险隐患排查，做好行业关键信息基础设施安全防护</p>
            </router-link>
          </li>
           <li>
            <router-link to="consultDetail1" target="_blank" class="prodLink">
              <p title="等保2.0和等保1.0分别有哪些标准文件" class="art_conent">2、等保2.0和等保1.0分别有哪些标准文件</p>
            </router-link>
          </li>
           <li>
            <router-link to="consultDetail2" target="_blank" class="prodLink">
              <p title="等级保护和网络安全法的关系？" class="art_conent">3、等级保护和网络安全法的关系？</p>
            </router-link>
          </li>
          <li>
            <router-link to="consultDetail3" target="_blank" class="prodLink">
              <p title="周晓峰：什么是信息安全风险评估" class="art_conent">4、周晓峰：什么是信息安全风险评估</p>
            </router-link>
          </li>
        </ul>
	</div>
</template>
<script type="text/javascript">
export default{
  data(){
    return{
      fetching:true,
    }
  },
  mounted(){
 
  if(this.$route.path=="/consultDetail"||this.$route.path=="/consultDetail1"||
    this.$route.path=="/consultDetail2"||this.$route.path=="/consultDetail3"||this.$route.path=="/articleList"
    ){
    this.fetching=false
  }else{
    this.fetching=true;
  }

  },
  methods:{
    showphone(msg){
      this.$notify.success({
          title: '提示',
          message: msg,
          duration:6000
        });
     }
  }

};
</script>


<style type="text/css" scoped>
.conten_right{
	width:312px;
	background:#f4f4f4;
	float:left;
  display: block;
  min-height: 100%;
	margin-left:8px;
}
.contant{
	background:#fff;
	margin-bottom:15px;
}
.contant h4{
	font-size:15px;
	color: #007CC6;
	font-weight: bold;
	line-height: 30px;
	padding-top:10px;
	text-align:center;
	background:#fff;
}
.contant .con_desc{
    font-size:13px;
	color: #949595;
	text-align: center;
	line-height:23px;
	margin-bottom:10px;
}
.con_box{
	padding:0 20px;
	padding-bottom:15px;
}
.con_box .con_item{
  overflow:hidden;
  margin-bottom:10px;
  margin-top:5px;
}
.con_box .con_item span{
  font-size:13px;
  color: #49494A;
  width:60px;
  display: inline-block;
}
.con_box .con_item input{
	width: 205px;
	height: 26px;
	padding-left:3px;
	border-radius:3px;
	border: 1px solid #CFCDCD;
}
.con_box .con_item textarea{
	border: 1px solid #CFCDCD;
	width: 205px;
	height: 57px;
	padding-left:3px;
	border-radius: 3px;
	resize: none;
}
.con_from{
	width: 84px;
	height: 29px;
	display: block;
	color:#fff;
	background: linear-gradient(0deg, #60AAF8 10%, #179BE9 100%);
	text-align:center;
	line-height: 29px;
	margin:0 auto;
	margin-top:10px;
	border-radius: 3px;
}
.conten_right .hots{
    display: block;
    height: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #353535;
    line-height: 30px;
    padding-left: 20px;
    border-left:3px solid #05A4FF;
    border-bottom:1px solid #ddd;
    background:#fff;
}
.recommandList{
	  width: 100%;
    padding:4px 20px;
    background:#fff;
    box-sizing: border-box;
    padding-bottom:30px;
}
.recommandList li{
	  display: flex;
    margin-top: 25px;
    cursor: pointer;
}
.recommandLeft{
	width: 90px;
  height: 70px;
  margin-right: 10px;
  background-color: skyblue;
  display: inline-block;
  float: left;
}
.recommandLeft>img{
	width:100px;
	height:70px;
	margin-right:3px;
}
.recommandRight{
	display: inline-block;
  width: 133px;
  padding: 0;
  height: 100%;
  padding-left:3px;
  box-sizing:border-box;
  float: left;
}
.recommandRight>h5 {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.recommandRight p {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    padding: 0;
    margin: 5px 0 0;
    overflow: hidden;
    line-height: 25px;
    margin-bottom:5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
 }
.recommandRight span {
    font-size: 12px;
    font-weight: 400;
    color: #FF6A00;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
}
.recommandRight>i{
    font-size: 12px;
    font-weight: 400;
    color: #FF6A00;
}
.art_conent{
  font-size:14px;
  color:#666;
  line-height: 20px;
  box-sizing: border-box;
  margin-right:20px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
.art_conent:hover{
  color:#0e82cb;
}
.reco_article li{
  margin-top:15px;
  padding-bottom:10px;
  border-bottom:1px solid #ccc;
}
.reco_article li a{
  display: block;
  width:100%;
}



</style>